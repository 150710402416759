<template>
  <div class="go-record-card p-4 bg-white">
    <div class="sub-title text-18 font-weight-bold bg-bgsection">
      {{ $t(title) }} {{ $t('- 最近一筆') }}
    </div>
    <div
      class="content"
      :class="hasRecord && event === 'kifu' ? 'my-3' : 'm-3'"
    >
      <div v-show="!hasRecord" class="no-record">{{ $t('目前沒有紀錄') }}</div>
      <slot name="content"></slot>
    </div>
    <div>
      <b-button
        variant="primary"
        size="md"
        class="btn-go-feature d-flex align-items-center justify-content-center ml-auto"
        @click="$emit('go-feature', event)"
      >
        {{ $t(title) }}<i class="icon-Chevron-right text-18 ml-1"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasRecord: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    event: {
      type: String,
      default: 'learningProcess',
    },
  },
};
</script>

<style lang="scss" scoped>
.go-record-card {
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  .sub-title {
    border-radius: $card-border-radius;
    padding: 5px 16px;
  }
  .content {
    color: black;
  }
  .btn-go-feature {
    width: 240px;
  }
}
</style>
