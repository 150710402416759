<template>
  <div
    class="profile-container d-flex align-items-start align-items-md-center justify-content-between"
    :class="{'jungo-profile-container': $isJungo}"
  >
    <div class="avatar-container d-flex align-items-center mr-4 my-md-2">
      <img
        class="rounded-circle"
        :srcset="
          require(`@/assets/img/personal/avatar/${
            userData.avatar || '0000'
          }.png?srcset`)
        "
      />
    </div>
    <div class="wrapper-user flex-fill text-truncate text-md-center">
      <div class="name font-weight-bold">
        <p class="m-0 text-truncate">{{ userData.nickName }}</p>
      </div>
      <p class="text-primary rank">
        {{ $t('棋力') + '：' + ranksMapping[userData.rank || 0].label }}
      </p>
    </div>
    <div
      class="edit-avatar d-flex justify-content-center align-items-center"
      :class="$isJungo ? 'bg-primary' : 'bg-secondary'"
      @click="openEdit"
    >
      <i class="icon-Edit"></i>
    </div>
    <div v-if="!$isJungo" class="jia-notice pt-1 w-100">
      <div class="d-flex my-4 align-items-center">
        <img
          class="rounded-circle mr-1"
          width="32"
          height="32"
          :srcset="require(`@/assets/img/personal/notice-jia.png?srcset`)"
        />
        <p class="m-0 ml-2 text-14 font-weight-bold">
          {{ $t('嘉嘉小提醒：') }}
        </p>
      </div>
      <div class="text-14 pt-1 mb-5">
        {{ $t(notice) }}
      </div>
    </div>
  </div>
</template>

<script>
import ranksMapping from '@/json/rank.json';
import _ from 'lodash';

export default {
  data() {
    return {
      ranksMapping,
      noticeList: [
        '想把圍棋學好，「下棋」是很重要的哦，快去下一盤棋吧！',
        '下完棋馬上「覆盤」，看看哪裡下得好、哪裡可以改進，進步會更快唷！',
        '多多參加直播講堂，和老師互動、討論，對棋力提升很有幫助哦！',
        '養成規律、穩定的學習習慣，是提升學棋效率的關鍵喔！',
      ],
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    notice() {
      return _.sample(this.noticeList);
    },
  },
  methods: {
    openEdit() {
      this.$emit('open-edit');
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  width: 100vw;
  padding: 24px 16px 0;
  @media screen and (min-width: 768px) {
    width: 100%;
    border-radius: $card-border-radius;
    background: white;
    box-shadow: $card-box-shadow;
    padding: 16px;
  }
  @media screen and (min-width: 1440px) {
    width: 300px;
    margin-right: 24px;
    flex-direction: column;
    height: auto;
    position: relative;
    padding: 16px 24px;
  }
  .edit-avatar {
    min-width: 38px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 28px;
    @media screen and (min-width: 768px) {
      margin-right: 14px;
      cursor: pointer;
    }
    @media screen and (min-width: 1440px) {
      position: absolute;
      right: 16px;
      top: 24px;
    }
  }
  .avatar-container {
    margin-left: 16px;
    @media screen and (min-width: 768px) {
      margin-left: 14px;
    }
    @media screen and (min-width: 1440px) {
      margin: 26px 0 0 !important;
    }
    img {
      width: 60px;
      height: 60px;
      @media screen and (min-width: 768px) {
        width: 100px;
        height: 100px;
      }
    }
  }
  .wrapper-user {
    margin: 0px 16px 0px 0;
    @media screen and (min-width: 1440px) {
      margin: 12px 0 0;
      padding-bottom: 28px;
      width: 100%;
      border-bottom: 1px solid $line-2;
    }
  }
  .name {
    line-height: 30px;
    font-size: 18px;
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
  .rank {
    border: 1px solid $primary;
    border-radius: 24px;
    line-height: 22.4px;
    display: inline-block;
    padding: 0 16px;
    margin: 4px 0 0;
    @media screen and (min-width: 768px) {
      margin: 8px 0 0;
    }
    @media screen and (min-width: 1440px) {
      margin: 24px 0 0;
    }
  }
  .jia-notice {
    display: none;
    @media screen and (min-width: 1440px) {
      display: block;
    }
  }
  .self-button {
    height: 48px;
  }
}
.jungo-profile-container {
  &.profile-container {
    padding: 24px 16px;
    background: $white;
    box-shadow: $card-box-shadow;
    .wrapper-user {
      @media screen and (min-width: 1440px) {
        border-bottom: none;
      }
    }
  }
}
</style>
