<template>
  <div
    class="personal h-100 d-flex flex-column align-items-center position-relative"
    :class="{'jungo-personal': $isJungo}"
  >
    <BaseHeader
      :title="$t('個人')"
      :is-title-bold="true"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
      :back-on-click-left="false"
      :has-red-dot="hasVerificationNotification"
      left-icon="Menu"
      @on-click-left="onClickSidebar"
    ></BaseHeader>

    <loading-wrapper v-if="isPageLoading" class="loading"></loading-wrapper>

    <div
      v-else-if="$device.isMobile"
      class="personal-wrapper overflow-overlay scroll-bar h-100"
    >
      <UserCard @open-edit="openEdit"></UserCard>
      <div v-if="!noPlans" class="box-container w-100 p-0">
        <b-container class="mt-3">
          <b-row no-gutters>
            <b-col
              v-for="(tab, index) of squareTabs"
              :key="tab.key"
              :class="{'mr-2': index !== squareTabs.length - 1}"
              @click="tabEvent(tab.key)"
            >
              <div
                class="tab-box bg-white d-flex flex-column align-items-center justify-content-center shadow-md"
              >
                <i class="mb-1 text-primary" :class="iconClass(tab)"></i>
                <div class="tab-title text-14">{{ tab.title }}</div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="box-container w-100">
        <b-container class="mt-3">
          <template v-for="tab of longTabs">
            <b-row
              v-if="tab.show && tab.key !== 'issuse'"
              id="longTab"
              :key="tab.key"
              :class="tabClass(tab)"
              @click="tabEvent(tab.key)"
            >
              <div ref="longTab" class="long-tab bg-white d-flex">
                <i :class="iconClass(tab)"></i>
                <div class="tab-title">{{ tab.title }}</div>
              </div>
            </b-row>
            <b-row v-if="tab.show && tab.key === 'issuse'" :key="tab.key">
              <div class="issuse-tab bg-white d-flex align-items-center w-100">
                <div class="flex-fill">
                  <div class="font-weight-bold">{{ tab.title }}</div>
                  <div class="text-14 mt-1">{{ tab.content }}</div>
                </div>
                <b-button
                  variant="primary"
                  size="md"
                  class="text-16"
                  pill
                  @click="onClick2Event(tab.link)"
                  >{{ $t('立即前往') }}</b-button
                >
              </div>
            </b-row>
          </template>
        </b-container>
      </div>
      <div v-if="appVersion" class="gitTag text-center text-12 mt-3 mb-3">
        {{ $t('目前版本：{version}', {version: appVersion}) }}
      </div>
      <action-sheet
        v-if="isEdit && $device.isMobile"
        ref="ActionSheet"
        :title="$t('編輯')"
        :touch-move-to-close="true"
        sheet-height="158"
        @close="isEdit = false"
      >
        <ul class="list-group">
          <li
            class="avatar list-group-item-action d-flex align-items-center text-dark"
            @click="sheetEvent('avatar')"
          >
            <i class="icon-Photo text-32 ml-3 mr-2"></i>
            <span>{{ $t('頭像') }}</span>
          </li>
          <li
            class="nickname list-group-item-action d-flex align-items-center text-dark"
            @click="sheetEvent('nickname')"
          >
            <i class="icon-Signature text-32 ml-3 mr-2"></i>
            <span>{{ $t('名字') }}</span>
          </li>
        </ul>
      </action-sheet>
    </div>
    <div v-else class="w-100 h-100 scroll-bar overflow-overlay">
      <div class="web-content">
        <div class="user-container">
          <UserCard @open-edit="openEdit"></UserCard>
          <template v-for="tab of longTabs">
            <div
              v-if="tab.show && tab.key === 'issuse'"
              :key="tab.key"
              class="issuse-tab d-flex"
            >
              <div class="flex-fill">
                <div class="font-weight-bold">{{ tab.title }}</div>
                <div class="text-14 mt-1">{{ tab.content }}</div>
              </div>
              <b-button
                variant="primary"
                size="md"
                class="text-16"
                pill
                @click="onClick2Event(tab.link)"
                >{{ $t('立即前往') }}</b-button
              >
            </div>
          </template>
        </div>
        <div class="wrapper-features">
          <h2 class="title mb-4">{{ $t('圍棋紀錄') }}</h2>
          <GoRecordCard
            v-if="false"
            title="學習歷程"
            class="mb-4"
            event="learningProcess"
            :has-record="learningProcess.length > 0"
            @go-feature="tabEvent"
          >
            <template v-if="learningProcess.length > 0" #content>
              <div class="learning-process-content">
                <p class="m-0 date">
                  {{ $day(learningProcess[0].createdAt).format('YYYY-MM-DD') }}
                </p>
                <p class="m-0">{{ learningProcess[0].content }}</p>
              </div>
            </template>
          </GoRecordCard>
          <GoRecordCard
            title="我的棋譜"
            event="kifu"
            :has-record="kifus.length > 0"
            @go-feature="tabEvent"
          >
            <template v-if="kifus.length > 0" #content>
              <div class="kifu-content d-flex">
                <div class="wrap-time-stamp d-flex">
                  <div
                    class="badge text-white mr-2"
                    :class="{'bg-secondary': kifus[0].isUserWon}"
                  >
                    <i v-if="kifus[0].isUserWon" class="icon-Crown"></i>
                    <i v-else class="icon-Flag"></i>
                  </div>
                  <p class="m-0">{{ kifus[0].time }}</p>
                </div>
                <div>{{ kifus[0].gameModeLabel }}</div>
                <div>{{ kifus[0].type }}</div>
                <div>{{ kifus[0].black }}</div>
                <div>{{ kifus[0].white }}</div>
                <div>{{ kifus[0].result }}</div>
                <div>
                  <b-button
                    size="sm"
                    class="mr-1 p-md-0"
                    variant="link"
                    @click="onCheckKifu(kifus[0])"
                  >
                    {{ $t('查看') }}
                  </b-button>
                  <div v-if="kifus[0].isReviewed" class="blue-dot"></div>
                </div>
              </div>
            </template>
          </GoRecordCard>
          <h2 class="title mb-4">{{ $t('所有功能') }}</h2>
          <div class="features">
            <template v-for="feature in webFeatures">
              <FeatureCard
                v-if="feature.show"
                :key="feature.key"
                :icon="feature.icon"
                :title="feature.title"
                :event="feature.event"
                @go-feature="tabEvent"
              ></FeatureCard>
            </template>
          </div>
        </div>
      </div>
    </div>
    <modal-wrapper
      v-if="isPersonalInfoEditing"
      :is-modal-wrapper-show="isPersonalInfoEditing"
      :is-show-transition="false"
      :click-to-close="true"
      @close="closeEdit"
    >
      <edit-personal-info @close="closeEdit" @click.native.stop="">
      </edit-personal-info>
    </modal-wrapper>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import ActionSheet from '@/components/Base/ActionSheet.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import UserCard from '@/components/Personal/UserCard.vue';
import GoRecordCard from '@/components/Personal/GoRecordCard.vue';
import FeatureCard from '@/components/Personal/FeatureCard.vue';

import ranksMapping from '@/json/rank.json';
import BackEvent from '@/lib/base/backEvent.js';
import kifuInfo from '@/lib/kifu/kifuInfo';
import filters from '@/lib/base/filters.js';
import {App} from '@capacitor/app';
import plansMixin from '@/mixin/plans';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {Browser} from '@capacitor/browser';
import {manifest} from '@/constant/env.js';

import userService from '@/services/user';
import aiGameService from '@/services/aiGame';

dayjs.extend(relativeTime);
dayjs.locale('zh-tw');

export default {
  components: {
    BaseHeader,
    ActionSheet,
    LoadingWrapper,
    UserCard,
    GoRecordCard,
    FeatureCard,
    ModalWrapper: () => import('@/components/Base/ModalWrapper.vue'),
    EditPersonalInfo: () =>
      import('@/components/Personal/EditPersonalInfo.vue'),
  },
  mixins: [plansMixin],
  data() {
    return {
      isPageLoading: false,
      ranksMapping,
      squareTabs: [
        {
          key: 'kifu',
          icon: 'Kifu',
          title: this.$t('我的棋譜'),
          event: 'kifu',
        },
        {
          key: 'courseStatus',
          icon: 'Star',
          title: this.$t('學習進度'),
          event: 'courseStatus',
        },
        {
          key: 'statistics',
          icon: 'Clipboard-list',
          title: this.$t('數據紀錄'),
          event: 'statistics',
        },
      ],
      webFeatures: [
        {
          key: 'nfts',
          icon: 'Go-nfts',
          title: 'NFTs',
          event: 'nfts',
          show: false,
        },
        {
          key: 'coupon',
          icon: 'Go-coupon',
          title: this.$t('優惠券'),
          event: 'coupon',
          show: false,
        },
        {
          key: 'certificate',
          icon: 'Certificate',
          title: this.$t('棋力證書'),
          event: 'certificate',
          show: false,
        },
        {
          key: 'live-course-record',
          icon: 'Clock',
          title: this.$t('直播時數'),
          event: 'liveCourseRecord',
          show: false,
        },
        {
          key: 'course-status',
          icon: 'Star',
          title: this.$t('學習進度'),
          event: 'courseStatus',
          show: true,
        },
        {
          key: 'statistics',
          icon: 'Clipboard-list',
          title: this.$t('數據紀錄'),
          event: 'statistics',
          show: true,
        },
      ],
      isEdit: false,
      isPersonalInfoEditing: false,
      isNameEditing: false,
      appVersion: null,
      isRecordLoading: false,
      learningProcess: [],
      kifus: [],
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    currentCourses() {
      return this.$store.getters['course/currentCourses'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    kifusType() {
      return this.$store.state.aiGame.kifusType;
    },
    longTabs() {
      return [
        {
          key: 'issuse',
          title: '《千古無同局》週年慶',
          content: '棋界首發 NFT 詳細資訊看這裡！',
          event: 'issuse',
          link: manifest.homePageUrl + '/events/gothroughtheages/',
          show: false,
        },
        {
          key: 'certificate',
          icon: 'Certificate',
          title: this.$t('棋力證書'),
          event: 'certificate',
          show: false,
        },
        {
          key: 'setting',
          icon: 'Setting',
          title: this.$t('設定'),
          event: 'setting',
          show: true,
        },
        {
          key: 'contact',
          icon: 'Headset',
          title: this.$t('聯絡我們'),
          event: 'contact',
          show: false,
        },
        {
          key: 'logout',
          icon: 'Exit',
          title: this.$t('登出'),
          event: 'logout',
          show: true,
        },
      ];
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
  },
  async created() {
    if (!this.noPlans) {
      this.isPageLoading = true;
      const learnignResult = await this.getLearningProcess();
      this.learningProcess = learnignResult?.data || [];
      const kifuResult = await this.getKifu();
      this.kifus = kifuResult?.data?.kifuList || [];
      if (this.kifus.length > 0) this.kifusToTable();
      this.isPageLoading = false;
    }
    await App.getInfo()
      .then((res) => {
        this.appVersion = res.version;
      })
      .catch(() => {
        console.error('App version cannot implemented on web.');
      });
  },
  methods: {
    iconClass(tab) {
      return `icon-${tab.icon}`;
    },
    tabClass(tab) {
      return `tab-${tab.key}`;
    },
    tabEvent(tab) {
      switch (tab) {
        case 'learningProcess':
          return this.$router.push({name: 'learning-process'});
        case 'statistics':
          return this.$router.push({name: 'statistics'});
        case 'certificate':
          return this.$router.push({name: 'certificate'});
        case 'kifu':
          return this.$router.push({name: 'kifus'});
        case 'password':
          return this.$router.push({name: 'password'});
        case 'setting':
          return this.$router.push({name: 'setting'});
        case 'contact':
          return this.$router.push({name: 'contact'});
        case 'liveCourseRecord':
          return this.$router.push({name: 'live-course-record'});
        case 'courseStatus':
          return this.$router.push({name: 'course-status'});
        case 'nfts':
          return this.$router.push({name: 'nfts'});
        case 'coupon':
          return this.$router.push({name: 'coupon'});
        case 'logout':
          return this.logout();
      }
    },
    sheetEvent(eventName) {
      BackEvent.popEvent();
      return this.$router.push({name: eventName});
    },
    logout() {
      return this.$store.dispatch('user/logout').then(() => {
        this.$JPush.updateTags();
      });
    },
    openEdit() {
      this.$router.push({name: 'personal-edit'});
    },
    closeEdit() {
      if (this.$device.isMobile) {
        this.$refs.ActionSheet.closeSheet();
        BackEvent.popEvent();
      } else {
        this.$emit('close');
        this.isPersonalInfoEditing = false;
      }
    },
    getLearningProcess() {
      const data = {
        username: this.userData.username,
        limit: 1,
      };
      return userService.getLearningProcess(data);
    },
    async onCheckKifu(kifu) {
      if (kifu.isReviewed) {
        const result = await this.reviewGame(kifu);
        this.$store.commit('aiGame/setKifuReviewGame', result);
      }
      this.$store.commit('aiGame/setKifuData', kifu);
      return this.$router.push(
        `/personal/kifu/${kifu.createdTime || kifu.id}/${kifu.gameMode}`
      );
    },
    async reviewGame(kifu) {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      try {
        const result = await aiGameService.reviewGame({
          id: kifu.id,
          gameMode: kifu.gameMode,
          coursePlan: this.currentPlan.id,
        });
        return result;
      } catch {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    getKifu() {
      return aiGameService.getKifus(1, this.userData.username, this.kifusType);
    },
    kifusToTable() {
      this.kifus.forEach((kifu) => {
        const finishedTime = this.timestamp(kifu.finishedTime);

        kifu.time = finishedTime;
        kifu.type = this.detail(kifu);
        kifu.black = `${kifuInfo.blackName(kifu)} [${kifuInfo.blackRank(
          kifu
        )}]`;
        kifu.white = `${kifuInfo.whiteName(kifu)} [${kifuInfo.whiteRank(
          kifu
        )}]`;
        kifu.result = filters.winningWayText(
          kifu.winningWay,
          kifu.komi,
          kifu.boardSize
        );
        kifu.isUserWon = this.isUserWon(kifu);
        kifu.gameModeLabel =
          kifuInfo.verificationRank(kifu) + kifuInfo.KifuType[kifu.gameMode];
      });
    },
    timestamp(finishedTime) {
      if (dayjs().diff(dayjs(finishedTime), 'h') < 24) {
        return dayjs(finishedTime).fromNow();
      } else {
        const format = 'YYYY-MM-DD';
        return dayjs(finishedTime).format(format);
      }
    },
    detail(kifu) {
      if (kifu.gameMode === 'pvp') {
        return `${kifu.boardSize}${this.$t('路')} - ${kifu.step}${this.$t(
          '手'
        )}`;
      } else {
        return `${kifu.gameTitle} - ${kifu.step}${this.$t('手')}`;
      }
    },

    isUserWon(kifu) {
      if (kifu.gameMode == 'pvp') {
        return (
          (kifu.winner == 'black' && this.userData.username == kifu.black) ||
          (kifu.winner == 'white' && this.userData.username == kifu.white)
        );
      }
      return kifu.userColor === kifu.winner;
    },
    async onClick2Event(url) {
      await Browser.open({
        url,
        presentationStyle: 'popover',
      });
    },
    onClickSidebar() {
      this.$store.commit('env/setIsSidebarShow', true);
      BackEvent.addEvent(() => {
        this.$store.commit('env/setIsSidebarShow', false);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal {
  min-height: calc(100% - 56px);
  @media screen and (min-width: 768px) {
    overflow: hidden;
  }
  .personal-wrapper {
    background: url('../../assets/img/personal/bg-mask.png') no-repeat top
      center;
    background-size: contain;
    @media screen and (min-width: 768px) {
      background: none;
    }
  }
  .box-container {
    padding: 0 16px;
    .main-features-wrapper {
      box-shadow: $card-box-shadow;
      border-radius: 10px;
      flex-wrap: wrap;
      > div[class*='tab'] {
        width: calc(25% - 0.5rem);
      }
      .tab-title {
        font-size: 14px;
        height: 22px;
        line-height: 22px;
      }
    }
    .long-tab {
      width: 100%;
      height: 51px;
      border-radius: 10px;
      box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
      padding: 10px 16px;
      margin-bottom: 12px;
      align-items: center;
      i {
        font-size: 32px;
        color: $primary;
        margin-right: 8px;
      }
      .tab-title {
        font-size: 16px;
        height: 20px;
        color: $font-grayscale-1;
      }
    }
    .issuse-tab {
      background: url('../../assets/img/personal/bg-issuse.png') no-repeat
          center center,
        #ffffff;
      background-size: cover;
      border-radius: 10px;
      padding: 12px 16px;
      margin-bottom: 12px;
      .btn {
        flex-shrink: 0;
      }
    }
  }
  .course-status {
    border-radius: 10px;
    box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
  }
  .gitTag {
    color: $font-grayscale-2;
  }
  .web-content {
    max-width: 720px;
    margin: 0 auto 40px;
    padding: 0 16px;
    @media screen and (min-width: 768px) {
      margin: 24px auto 92px;
    }
    @media screen and (min-width: 1440px) {
      max-width: 1140px;
      margin: 0 auto 92px;
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      margin-top: 24px;
    }
    .user-container {
      @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
      }
      @media screen and (min-width: 1440px) {
        flex-direction: column;
      }
    }
    .issuse-tab {
      background: url('../../assets/img/personal/bg-issuse.png') no-repeat
          center top,
        #ffffff;
      background-size: cover;
      border-radius: 10px;
      padding: 12px 16px;
      .btn {
        flex-shrink: 0;
      }
      @media screen and (min-width: 768px) {
        width: 200px;
        flex-direction: column;
        flex-shrink: 0;
        margin-left: 24px;
        align-items: flex-start;
      }
      @media screen and (min-width: 1440px) {
        width: calc(100% - 24px);
        flex-direction: row;
        margin-left: 0;
        align-items: center;
        margin-top: 24px;
      }
    }
    .learning-process-content {
      line-height: 28px;
      .date {
        color: #606266;
      }
    }
    .kifu-content {
      position: relative;
      height: 60px;
      width: 100%;
      padding: 16px 10px;
      align-items: center;
      background: $bgcontainer;
      > div {
        flex: 1 1 0;
      }
      > div:not(:first-of-type):not(:last-of-type) {
        margin: 0 10px;
      }
      > div:first-of-type {
        margin: 0 10px 0 0;
      }
      > div:last-of-type {
        margin: 0 0 0 10px;
      }
      .wrap-time-stamp {
        align-items: center;
        p {
          flex: 1 0 0;
        }
      }
      .badge {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 12px;
        background: $grayscale-30;
        font-size: 20px;
        padding: 2px 0px 2px;
      }
      .blue-dot {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 14px;
        height: 14px;
        background-color: $infoAndLink;
        border-radius: 50%;
      }
    }
    .wrapper-features {
      @media screen and (min-width: 1440px) {
        flex: 1 1 0;
      }
      h2.title {
        margin-top: 34px;
        padding-bottom: 10px;
        border-bottom: 1px solid $line-2;
        @media screen and (min-width: 1440px) {
          margin-top: 0;
        }
      }
      .features {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
      }
    }
  }
}

.list-group-item-action {
  height: 51px;
  border-bottom: 1px solid rgba(199, 199, 199, 0.3);
}

.jungo-personal {
  .personal-wrapper {
    background: none;
  }
  .box-container {
    .col .tab-box .tab-title,
    .long-tab .tab-title {
      color: $font-normal;
    }
    .tab-box {
      border-radius: 10px;
      padding: 20px 0;
      i {
        font-size: 32px;
        color: $secondary;
      }
      .tab-title {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
