<template>
  <div class="feature-card p-4 bg-white">
    <div class="sub-title d-flex align-items-center font-weight-bold">
      <i class="text-28 text-primary mr-3" :class="`icon-${icon}`"></i>
      {{ $t(title) }}
    </div>
    <b-button
      :variant="$isJungo ? 'primary' : 'outline-primary'"
      size="md"
      class="w-100"
      @click="$emit('go-feature', event)"
    >
      {{ $t('查看') }}
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'Certificate',
    },
    title: {
      type: String,
      default: '棋力證書',
    },
    event: {
      type: String,
      default: 'certificate',
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-card {
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  .sub-title {
    margin-bottom: 20px;
  }
}
</style>
